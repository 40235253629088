<template>
  <v-card
    flat
    class="bg-card-panel elevati"
    :loading="loadTask"
  >
    <template slot="progress">
      <v-progress-linear
        color="icono"
        height="3"
        indeterminate
      />
    </template>
    <v-card-title class="d-flex justify-space-between align-center">
      <h3 class="blue-grey--text text--darken-3 font-weight-medium text-capitalize">
        <v-icon size="20">mdi-bookmark</v-icon>
        {{ details.label }}
      </h3>
      <slot name="switch">
        <v-switch
          v-model="loadTask"
          inset
          dense
          hide-details
          class="mt-0 mr-n2"
          :disabled="loadTask"
          @change="runTask(details.action, details.task, details.query || null, details.method)"
        />
      </slot>
    </v-card-title>
    <v-card-text class="d-flex align-center">
      <v-avatar
        :color="randomColor"
        size="50"
        class="rounded-xl"
      >
        <v-icon
          size="32"
          color="grey lighten-4"
          v-text="details.icon"
        />
      </v-avatar>
      <h2
        class="ml-3 primary--text font-weight-bold text-capitalize"
        style="line-height: 1.2em"
        v-text="details.title"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import { sendTasks } from '@/services/tasks.js'
const COLORS = ["info","orange", "purple", "deep-orange", "icono", "indigo", "teal", "green", "blue-grey"]

export default {
  name: 'CardTask',
  props: {
    details: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    loadTask: false,
  }),
  computed: {
    randomColor () {
      const indexRandom = Math.floor(Math.random() * 8) + 1;
      return COLORS[indexRandom]
    }
  },
  methods: {
    async runTask (action, task, options, method) {
      if(this.loadTask) {
        try {
          const { message } = await sendTasks({ action, task, options, method })
          this.$root.$showAlert(message, 'success')
          this.$emit('update')
        } catch (error) {
          this.$root.$showAlert(
            'Lo sentimos, hubo un error al intentar realizar esta acción en el Servidor.',
            'error',
          )
        } finally {
          this.loadTask = false
        }
      }
    },
  },
}
</script>
<style>
.bg-card-panel {
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(240,244,249,1) 17%, rgba(211,224,238,1) 100%);
  border: 1px solid rgba(0,0,0,0.04) !important;
}
</style>
